import { render, staticRenderFns } from "./chemGhs1.vue?vue&type=template&id=0a63a6df"
import script from "./chemGhs1.vue?vue&type=script&lang=js"
export * from "./chemGhs1.vue?vue&type=script&lang=js"
import style0 from "./chemGhs1.vue?vue&type=style&index=0&id=0a63a6df&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\psm\\PSMFrontEnd\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a63a6df')) {
      api.createRecord('0a63a6df', component.options)
    } else {
      api.reload('0a63a6df', component.options)
    }
    module.hot.accept("./chemGhs1.vue?vue&type=template&id=0a63a6df", function () {
      api.rerender('0a63a6df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mdm/mam/chemGhs1.vue"
export default component.exports